/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FeatureAppLoader } from '@feature-hub/react';
import { audiLightTheme } from '@audi/audi-ui-react';
import { VideoPlayerAssetConfig, getVideoPlayerConfig } from '../../utils/getVideoPlayerConfig';
import { Context } from '../../Context';
import { AssetTypeVideo, Mode } from '../../../types';
import { ResponsiveMediaItemBase } from '../stage-image/StageImage';
import { useWindowSize } from '../../hooks';
import { throttleScroll } from '../../utils/throttleScroll';

const VIDEO_PLAYER_FA_NAME = 'fa-video-player';
const VIDEO_PLAYER_FA_VERSION = 'v5.2.1';
const VIDEO_PLAYER_FA_ENV = 'prod';
const VIDEO_PLAYER_FA_BASE_URL = `https://${VIDEO_PLAYER_FA_NAME}.cdn.${VIDEO_PLAYER_FA_ENV}.collab.apps.one.audi/${VIDEO_PLAYER_FA_VERSION}`;

const VideoWrapper = styled(ResponsiveMediaItemBase)`
  width: auto;
  img {
    display: inline !important;
  }
  /// Add bars below and on top if the video is too wide, this is mainly to preserve the sub titles
  ${({ preserveVideoWidth }) =>
    preserveVideoWidth &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  `}
`;

export interface StageVideoProps {
  asset: AssetTypeVideo;
  mode: Mode;
}

const StageVideo: React.FC<StageVideoProps> = (props: StageVideoProps) => {
  const { asset, mode } = props;
  const { width } = useWindowSize();

  const videoRef = useRef<HTMLDivElement>(null);
  // Determine the device category and set the video player config accordingly
  const { m } = audiLightTheme.breakpoints;
  const isMobile = width < m;
  const responsiveMediaAsset: VideoPlayerAssetConfig = {
    ...asset,
    src: isMobile ? asset.portrait : asset.landscape || asset.portrait,
  };

  if (!responsiveMediaAsset.src) {
    // eslint-disable-next-line no-console
    console.error('No video source found');
    return null;
  }

  const videoPlayerConfig = useMemo(() => getVideoPlayerConfig(responsiveMediaAsset), [asset]);
  const [scrollY, setScrollY] = useState<number>(0);
  const [, setIsInViewport] = useState<boolean | null>(null);
  const [isAutoplay] = useState<boolean>(asset.autoPlay || false);

  const [isPlaying, setIsPlaying] = useState<boolean>(() => {
    return isAutoplay;
  });

  const setVideoEvents = () => {
    const video = videoRef.current?.querySelector('video');
    if (!video) {
      // eslint-disable-next-line no-console
      console.error('video element not found!');
      return;
    }

    video.addEventListener('play', () => {
      setIsPlaying(true);
    });
    video.addEventListener('ended', () => {
      setIsPlaying(false);
    });
  };

  useEffect(() => {
    // skip if ssr
    /* istanbul ignore next */
    if (!document || !window) {
      return () => {};
    }

    // execute `onScroll` max. every 100 milliseconds
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const onScroll = throttleScroll(() => setScrollY(Number(window.scrollY)), 100) as () => void;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    document.addEventListener('scroll', onScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const { videoService, featureAppEnv } = useContext(Context);
  const videoPlayerId = useMemo(
    () => `${featureAppEnv.featureAppId}_${VIDEO_PLAYER_FA_NAME}`,
    [featureAppEnv, VIDEO_PLAYER_FA_NAME],
  );

  useEffect(() => {
    // skip ssr and loading state
    if (videoRef.current === null || !window || !videoService) {
      return;
    }

    const rect = videoRef.current.getBoundingClientRect();
    const outTop = rect.top + rect.height < 0;
    const outBottom = rect.top > window.innerHeight;
    const isInViewport_ = !outTop && !outBottom;

    setIsInViewport((wasInViewport) => {
      // case: initial value => return first "real" value
      // and don't interfere with video settings like `autoplay`
      /* istanbul ignore next */
      if (wasInViewport === null) {
        return isInViewport_;
      }

      // https://github.com/testing-library/react-testing-library/issues/671
      /* istanbul ignore next */
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async (): Promise<void> => {
        try {
          // case: video scrolls out
          if (wasInViewport && !isInViewport_ && isPlaying) {
            videoService.stop(videoPlayerId);
            setIsPlaying(false);
            setTimeout(() => {
              const videoElement = videoRef.current?.querySelector('video');
              if (videoElement && videoElement.currentTime >= 0) {
                videoElement.currentTime = 0;
              }
            }, 200);
          }

          // case: video scrolls in
          if (!wasInViewport && isInViewport_ && isAutoplay) {
            await videoService.play(videoPlayerId);
          }
        } catch (error) {
          // play failed due to browser policies:
          // Safari: https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari
          // Chrome: https://goo.gl/xX8pDD
        }
      })();

      /* istanbul ignore next */
      return isInViewport_;
    });
  }, [isPlaying, isAutoplay, scrollY, videoService, videoPlayerId]);

  return (
    <VideoWrapper
      mode={mode}
      preserveVideoWidth={asset.preserveVideoWidth}
      data-test-id="fa-editorial-stage-video"
      onLoad={setVideoEvents}
      ref={videoRef}
    >
      <FeatureAppLoader
        featureAppId={videoPlayerId}
        featureAppName={VIDEO_PLAYER_FA_NAME}
        config={videoPlayerConfig}
        serverSrc={`${VIDEO_PLAYER_FA_BASE_URL}/fh/app.node.js`}
        src={`${VIDEO_PLAYER_FA_BASE_URL}/fh/app.js`}
      />
    </VideoWrapper>
  );
};

export default StageVideo;
